/********** Start of Karyakarta **************/
.karya_heading{
    padding: 20px 0px;
}
.karya_heading h4{
    color: #EC7024;
    text-align: center;
    font-weight: bold;
    font-size: 1.2rem;
    margin: 10px auto;
}
.karya_list ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.karya_list ul li{
    margin: 0;
    padding: 10px 0px;
    display: block;
}
.karya_list ul li:nth-child(odd){
    background: #fff;
}
.karya_list ul li:nth-child(even){
    background: rgba(66, 68, 158, 0.04);
}
.karya_list ul li .voter_container .karya_innerlist ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
}
.karya_list ul li .voter_container .karya_innerlist ul li{
    margin: 0;
    padding: 0;
    background: none;
}
.karya_list ul li .voter_container .karya_innerlist ul li h6{
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.55);
}
.karya_list ul li .voter_container .karya_innerlist ul li h6.karta_color{
    color: #EC7024;
}
.karya_list ul li .voter_container .karya_innerlist ul li h6.karta_value{
    text-align: right;
}
.karya_list ul li .voter_container .karya_innerlist ul li button{
    outline: none;
    color: rgba(236, 112, 36, 1);
    font-size: 0.7rem;
    margin: 0px 0px 0px 10px;
    background: none;
    border: none;
    font-weight: bold;
}
.karya_list1 p{
    margin: 20px auto;
    color: #707070;
    font-size: 1rem;
    line-height: 22px;
    word-spacing: 1px;
}
.karya_list1 ul{
    list-style-type: none;
    display: table;
    width: 100%;
    padding: 30px 0px;
    text-align: center;
}
.karya_list1 ul li{
    margin: 0;
    padding: 0;
    display: table-cell;
    width: 50%;
}
.karya_list1 ul li:nth-child(1){
    border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.karya_list1 ul li h5{
    color: rgba(0, 0, 0, 0.5);
    font-size: 1rem;
    font-weight: normal;
    margin: 0px auto;
}
.karya_list1 ul li h4{
    font-size: 2rem;
    margin: 10px auto;
    font-weight: normal;
}
.karya_list1 ul li h4 span{
    color: #EC7024;
}
.karyalist_profile{
    
    margin: 20px auto;
}
.karyalist_profile ul{
    background: #fff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    list-style-type: none;
    margin: 20px auto 10px auto;
    display: flex;
    align-items: flex-start;
}
.karyalist_profile ul li{
    padding: 0px 10px 0px 0px;
}
.karyalist_profile ul li:nth-child(1){
    width: 28%;
}
.karyalist_profile ul li:nth-child(2){
    width: 72%;
}
.karyalist_profile ul li img.user_img{
    width: 70px;
}
.karyalist_profile ul li h6{
    font-size: 0.8rem;
    font-weight: normal;
    margin: 5px auto;
    position: relative;
}
.karyalist_profile ul li h6 img.karya_delete{
    position: absolute;
    right: -20px;
    top: -15px;
}
.karyalist_profile ul li h6.phone_position{
    position: relative;
}
.karyalist_profile ul li h6.phone_position img.user_icon{
    position: relative;
    top: 3px;
}
.karyalist_profile ul li h6.phone_position img.phone_icon{
    width: 20px;
    position: absolute;
    right: -20px;
    top: 0px;
}
.karyalist_profile ul li h6 img.user_icon{
    width: 10px;
    margin: 0px 10px 0px 0px;
    position: relative;
    top: 2px;
}
.karyalist_profile ul li h6 button{
    border: 1px solid #FFE8C8;
    background: #FFE8C8;
    color: #EC7024;
    padding: 5px 10px;
    border-radius: 20px;
    position: absolute;
    right: -20px;
    font-size: 0.7rem;
}
/********** End of Karyakarta ************/

/********** Start of Karyakarta Popup ************/
.karya_popup{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99;
}
.kar_assign_voter{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 10px 20px 20px 20px;
    border-radius: 10px;
    width: 80%;
}
.kar_assign_voter h5{
    color: #000;
    text-align: center;
    font-weight: normal;
    font-size: 1.2rem;
    margin: 10px auto;
}
.kar_assign_voter ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
}
.kar_assign_voter ul li{
    margin: 0;
    padding: 0px 30px 0px 0px;
}
.kar_assign_voter ul li h6{
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.5);
    margin: 10px auto;
    font-weight: normal;
}
.kar_assign_voter ul li input{
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border-radius: 10px;
    border: 1px solid #f8f8f8;
    outline: none;
    background: #F8F8F8;
}
.kar_assign_voter ul li input::placeholder{
    color: #000;
}
button.save_btn{
    background: #EC7024;
    border: 1px solid #EC7024;
    color: #fff;
    padding: 8px 0px;
    border-radius: 3px;
    outline: none;
    margin: 20px 10px 0px 0px;
    font-size: 1rem;
    width: 100%;
}
button.cancel_btn{
    background: #fff;
    border: 1px solid #EC7024;
    color: #EC7024;
    padding: 8px 0px;
    border-radius: 3px;
    outline: none;
    margin: 20px 10px 0px 0px;
    font-size: 1rem;
    width: 100%;
}

.assign-voters{
    text-decoration: none;
    background: #FFE8C8;
    color: #EC7024;
    padding: 5px 15px;
    border-radius: 20px;
    font-size: 0.8rem;
    margin: 0px 0px 0px 10px;
    float: right;
}
/********** End of Karyakarta Popup ************/