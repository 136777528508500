/******** Start of Section of Voter List Data ************/
.voter_search{
    padding: 10px 0px;
    position: relative;
    text-align: right;
}
.voter_search input{
    padding: 15px 10px 15px 35px;
    background: none;
    border: 1px solid #F8F8F8;
    background: #F8F8F8;
    border-radius: 10px;
    width: 100%;
    font-size: 0.8rem;
    outline: none;
}

.voter_search img{
    position: absolute;
    left: 15px;
    top: 25px;
    width: 12px;
}

.voterlist_detail{
    background: #fff;
    border-radius: 20px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    margin: 0px auto;
    padding: 15px;
}
.voterlist_profile ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: flex-start;
}
.voterlist_profile ul li{
    margin: 0px;
    padding: 0px;
}

.voterlist_profile ul li.dot_position{
    position: relative;
}
.voterlist_profile ul li.dot_position img.red_dot{
    position: absolute;
    bottom: 2px;
    right: 15px;
    width: 22px;
}
.voterlist_profile ul li.voter_check button{
    background: none;
    outline: none;
    border: none;
    margin-right: 10px;
}
.voterlist_profile ul li.voter_check button img{
    width: 20px;
}
.voterlist_profile ul li:nth-child(1){
    padding: 0px 10px 0px 0px;
}
.voterlist_profile ul li:nth-child(2){
    flex-grow: 1;
}
.voterlist_profile ul li img.user_img{
    width: 80px;
    border-radius: 50%;
    height: 80px;
}
.voterlist_profile ul li h5{
    font-size: 1.2rem;
    margin: 0px auto;
    color: #000;
}
.voterlist_profile ul li button.survey_btn{
    background: #0AC863;
    border: 1px solid #0AC863;
    color: #fff;
    text-transform: uppercase;
    font-size: 0.8rem;
    padding: 4px 18px 8px 18px;
    margin: 10px 0px 10px 0px;
    border-radius: 10px;
    display: block;
}
.voterlist_profile ul li .voterlist_img{
    display: flex;
}
.voterlist_profile ul li .voterlist_img p{
    margin-right: 10px;
    position: relative;
}
.voterlist_profile ul li .voterlist_img p img{
    width: 28px;
}
.voterlist_profile ul li .voterlist_img p span{
    position: absolute;
    background: #EC7024;
    border: 1px solid #fff;
    color: #fff;
    bottom: 10px;
    font-size: 0.8rem;
    padding: 0px 7px;
    border-radius: 30px;
    right: -10px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    font-weight: bold;
}
.voterlist_profile ul li button.survey_btn img{
    margin-right: 5px;
    position: relative;
    top: 2px;
}
.voterlist_profile ul li h6{
    font-size: 0.9rem;
    color: rgba(0, 0, 0, 0.5);
}
.voterlist_profile ul li button{
    background: none;
    border: none;
    padding: 10px;
    border-radius: 5px;
}
.voter_number{
    padding: 5px 0px 0px 0px;
}
.voter_number ul{
    list-style-type: none;
    margin: 0px;
    padding: 8px 0px;
    display: table;
    width: 100%;
}
.voter_number ul li{
    margin: 0;
    display: table-cell;
    padding: 0;
    vertical-align: top;
}
.voter_number ul li:nth-child(1){
    width: 40%;
}
.voter_number ul li:nth-child(2){
    width: 60%;
}
.voter_number ul li img{
    opacity: 0.3;
    width: 15px;
}
.voter_number ul li img.user_number{
    opacity: 1;
    width: 25px;
    position: absolute;
    top: -5px;
    right: 40px;
    
}
.voter_number ul li img.user_msg{
    opacity: 1;
    width: 25px;
    position: absolute;
    top: -5px;
    right: 7px;
    
}
.voter_number ul li h6{
    color: rgba(0, 0, 0, 0.5);
    font-size: 1rem;
    font-weight: normal;
    word-break: break-word;
}

.voter_number ul li h6.voter_card{
    color: #000;
    margin-right: 5px;
}
.voter_number ul li.radio_select{
    display: flex;
}
.voter_number ul li.radio_select label{
    margin-right: 10px;
}
.voter_marked ul{
    list-style-type: none;
    margin: 20px auto;
    padding: 0;
    display: flex;
    justify-content: center;
}
.voter_marked ul li{
    margin: 0px auto;
    padding: 0px 2px;
    width: 50%;
    text-align: center;
}
.voter_marked ul li button{
    color: #EC7024;
    border: 1px solid #EC7024;
    text-transform: uppercase;
    padding: 10px 20px;
    background: none;
    font-weight: normal;
    border-radius: 10px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    outline: none;
    font-size: 0.8rem;
    width: 100%;
}
.voter_marked ul li button img{
    position: relative;
    top: 2px;
    left: -2px;
}
.voter_marked ul li button.mark_vote{
    background: #EC7024;
    border: 1px solid #EC7024;
    color: #fff;
    padding: 6px 20px 10px 20px;
}
.voter_marked ul li button.generate_btn{
    background: #FFE8C8;
}
.voter_question select {
    width: 100%;
    padding: 12px 10px;
    margin: 10px auto 0px auto;
    font-size: 1rem;
    background: #F8F8F8;
    border-radius: 12px;
    box-shadow: none;
    color: rgba(0, 0, 0, 0.8);
    outline: none;
    border: 1px solid #F8F8F8;
}
.beneficiary_popup{
    position: fixed;
    background: rgba(0, 0, 0, 0.2);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.beneficiary_content{
    background: #fff;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    padding: 10px;
    border-radius: 10px;
    width: 80%;
}
.beneficiary_content p{
    text-align: right;
    text-transform: uppercase;
    font-size: 1.5rem;
    margin: 0px auto;
}
.beneficiary_content p span{
    
    position: relative;
    z-index: 999;
}
.beneficiary_content h4{
    text-align: center;
    margin: -15px auto 10px auto;
}
.beneficiary_content ul{
    list-style-type: none;
    margin: 0;
    padding: 10px 0px;
    display: flex;
    align-items: flex-start;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.beneficiary_content ul li{
    margin: 0;
    padding: 0px 10px 0px 0px;
}
.beneficiary_content ul li img{
    width: 58px;
}
.beneficiary_content ul li h5{
    font-size: 1rem;
    margin: 5px auto;
}
.beneficiary_content ul li p{
    color: rgba(0, 0, 0, 0.6);
    margin: 0px auto;
    font-size: 0.9rem;
}
/******** End of Section of Voter List Data ************/ 

/* List and Grid View css code */
/* .filter-buttons {
    display: flex;
    margin-bottom: 20px;
}
.list-view-button,
.grid-view-button {
  color: black;
  border: 1px solid black;
  padding: 5px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 3px;
}

.list-view-button:hover,
.grid-view-button:hover {
  background: black;
  color: #0e2439;
}

.list-view-button {
  margin-right: 10px;
}
 */
 .listgrid_flex{
     /* display: flex;
     flex-wrap: wrap; */
     columns: 2;
     column-gap: 0px;
 }
 .list_grid_padding{
     padding: 2px;
     width: 100%;
     page-break-inside: avoid;
 }
 .grid_border{
     border: 1px solid rgba(0, 0, 0, 0.2);
     border-radius: 5px;
     padding: 5px 10px;
 }
 .filter-buttons{
     margin-top: 20px;
 }
 .filter-buttons ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-end;
 }
 .filter-buttons ul li{
     margin: 0;
     padding: 0px 0px 0px 15px;
 }
 .voterlist_img{
    display: flex;
}
.voterlist_img p{
    margin-right: 10px;
    position: relative;
}
.voterlist_img p span{
    position: absolute;
    background: #EC7024;
    border: 1px solid #fff;
    color: #fff;
    bottom: 10px;
    font-size: 0.8rem;
    padding: 0px 7px;
    border-radius: 30px;
    right: -10px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    font-weight: bold;
}
.voterlist_img p.grid_img_width img{
    width: 20px;
}
.voterlist_img p.grid_img_width span{
    position: absolute;
    background: #EC7024;
    border: 1px solid #fff;
    color: #fff;
    bottom: -4px;
    font-size: 0.7rem;
    padding: 0px 4px;
    border-radius: 50px;
    left: 10px;
    box-shadow: 2px 2px 10px rgb(0 0 0 / 10%);
    font-weight: bold;
    width: 15px;
    height: 15px;
    line-height: 14px;
}
.voterlist_img p.grid_grow{
    flex-grow: 1;
}