
.voter_checkbox ul{
    list-style-type: none;
    margin: 10px auto;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}
.voter_checkbox ul li{
    margin: 10px 0px;
    padding: 0px 10px;
}
.voter_submit button{
    background: #EC7024;
    color: #fff;
    text-transform: uppercase;
    font-size: 1rem;
    border-radius: 12px;
    margin: 20px auto;
    display: block;
    text-align: center;
    outline: none;
    padding: 10px;
    border: none;
    width: 100%;
}
/* The container_check */
.container_check2 {
    display: block;
    position: relative;
    padding-left: 20px;
    margin-top: 0px;
    cursor: pointer;
    font-size: 1rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .container_check2 input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark2 {
    position: absolute;
    top: 2px;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: #fff;
    border: 1px solid #EC7024;
    border-radius: 2px;
  }
  
  /* On mouse-over, add a grey background color */
  .container_check2:hover input ~ .checkmark2 {
    background-color: #fff;
  }
  
  /* When the checkbox is checked, add a blue background */
  .container_check2 input:checked ~ .checkmark2 {
    background-color: #EC7024;
    border: 1px solid #EC7024;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark2:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .container_check2 input:checked ~ .checkmark2:after {
    display: block;
    content: "";
  }
  
  /* Style the checkmark/indicator */
  .container_check2 .checkmark2:after {
    left: 9px;
    left: 4px;
    top: 1px;
    width: 6px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
/******** End of Section of Voter Detail ************/