/******** Start of Voter header menu ************/
.votermenu_list ul{
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    overflow-x: auto;
}
.votermenu_list ul::-webkit-scrollbar {
    -webkit-appearance: none;
    /* width: 10px; */
    height: 0px;
}
.votermenu_list ul li{
    margin: 0;
    padding: 30px 30px 5px 30px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}
.votermenu_list ul li a{
    text-decoration: none;
    text-transform: capitalize;
    color: #A7A7A7;
    white-space: nowrap;
}
.votermenu_list ul li.active_votermenu{
    border-bottom: 2px solid #EC7024;
}
.votermenu_list ul li.active_votermenu a{
    color: #EC7024;
    
}
.personal_updatelist ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.personal_updatelist ul li{
    margin: 0;
    padding: 0;
}
.personal_updatelist ul li h6{
    font-size: 0.7rem;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 400;
    margin: 5px auto;
}
.personal_updatelist ul li select{
    background: none;
    border: none;
    font-size: 0.9rem;
}
.personal_updatelist ul li h5.text_right{
    text-align: right;
    font-size: 0.9rem;
    font-weight: 400;
    margin: 0px auto;
}
.personal_updatelist ul li h6.text_right{
    text-align: right;
    font-size: 0.7rem;
    font-weight: 400;
    margin: 5px auto;
}
/******** End of Voter header menu ************/