/******** Start of header tabs ************/

.voter_question2 h6 {
  font-weight: 500;
  font-size: 1rem;
  margin: 0px auto 0px auto;
}
.voter_question2 img.user_profile {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: block;
  margin: 10px auto;
}
.voter_question2 ul {
  list-style-type: none;
  margin: 20px auto;
  padding: 0;
  display: flex;
  justify-content: space-around;
}
.voter_question2 ul li {
  padding: 0px 5px;
  position: relative;
  margin: 0px;
}
.voter_question2 ul li input {
  width: 100%;
  margin: 0px;
  padding: 0px;
  box-shadow: none;
  opacity: 0;
  height: 40px;
}
.voter_question2 ul li button {
  background: #ffe8c8;
  border: 1px solid #ffe8c8;
  color: #ec7024;
  padding: 10px;
  border-radius: 20px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  font-size: 0.8rem;
}
.voter_question2 ul li button img {
  margin-right: 5px;
  position: relative;
  top: 2px;
}
.voter_question2 input {
  width: 100%;
  padding: 12px 10px;
  margin: 10px auto;
  font-size: 1rem;
  background: #f8f8f8;
  border-radius: 15px;
  color: rgba(0, 0, 0, 0.8);
  outline: none;
  border: 1px solid #f8f8f8;
}
.voter_question2 input::placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.voter_question2 input.land_codet {
  position: absolute;
  top: 0;
  width: 25%;
  background: none;
  border: none;
  box-shadow: none;
}
.voter_question2 select {
  width: 100%;
  padding: 12px 10px;
  margin: 10px auto 20px auto;
  font-size: 1rem;
  background: #f8f8f8;
  border-radius: 12px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.6);
  outline: none;
  border: 1px solid #f8f8f8;
}
.voter_question2 input.picture_input {
  width: 90%;
}
.voter_question2 input.land_input {
  padding-left: 90px;
}
.voter_question2 input.land_input::before {
  content: "x";
  background: green;
  color: green;
}
.voter_question2 select.land_code {
  position: absolute;
  width: 22%;
  top: 0px;
  left: 0px;
  background: none;
  border: none;
  box-shadow: none;
}
.voter_question2 span.select_border {
  font-size: 1.7rem;
  position: absolute;
  top: 20px;
  left: 80px;
  opacity: 0.2;
}
.plus_position {
  position: relative;
}
.plus_position img.plus_circle {
  position: absolute;
  right: 0px;
  top: 15px;
  width: 30px;
  cursor: pointer;
}
img.plus_circle {
  position: absolute;
  right: 0px;
  top: 15px;
  width: 30px;
  cursor: pointer;
}
button.minus_btn {
  background: none;
  border: none;
  position: absolute;
  top: 15px;
  right: 0px;
  outline: none;
}
button.minus_btn img {
  width: 30px;
}
.voter_question2 ul.gender_listing {
  list-style-type: none;
  margin: 10px 0px 0px 0px;
  padding: 0;
  display: flex;
  justify-content: space-between;
}
.voter_question2 ul.pol_listing {
  justify-content: flex-start;
}

.voter_question2 ul.gender_listing li {
  margin: 0;
  padding: 0;
}
.voter_question2 ul.pol_listing li {
  padding: 0px 40px 0px 0px;
}
.voter_question2 h6 span.voter_subheading {
  color: rgba(0, 0, 0, 0.5);
  position: relative;
  opacity: 1;
  font-size: 1rem;
  top: 0;
  left: 0;
}
/* The personal_container */
.personal_container {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.personal_container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  top: 0;
  left: 0;
}

/* Create a custom radio button */
.personal_checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #fff;
  border-radius: 50%;
  border: 1.5px solid #ec7024;
}

/* When the radio button is checked, add a blue background */
.personal_container input:checked ~ .personal_checkmark {
  background-color: #fff;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.personal_checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.personal_container input:checked ~ .personal_checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.personal_container .personal_checkmark:after {
  top: 2.2px;
  left: 2.2px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #ec7024;
}

.voter_question2 ul.voter_year {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: table;
  width: 100%;
}
.voter_question2 ul.voter_year li {
  margin: 0px;
  padding: 0px 0px;
  display: table-cell;
}
.voter_question2 ul.voter_year li:nth-child(2) {
  padding: 0px 10px;
  text-align: center;
  font-size: 1.2rem;
}
.voter_question2 textarea {
  margin: 20px auto;
  width: 100%;
  resize: none;
  min-height: 80px;
  border-radius: 5px;
  padding: 10px;
  outline: none;
  border: 1px solid #f8f8f8;
  background: #f8f8f8;
}
.political_check {
  border-top: 1px dashed rgba(0, 0, 0, 0.5);
}
.political_check ul li {
  padding: 0px 20px 0px 0px;
}
.political_check ul li label span.checkmark {
  border: 1.5px solid #ec7024;
}
.select_margin {
  margin: 10px auto;
}
.voter_question2 .css-yk16xz-control {
  background: #f8f8f8 !important;
  border: 1px solid #f8f8f8 !important;
  border-radius: 16px !important;
  height: 38px !important;
}
.voter_question2 .css-1okebmr-indicatorSeparator {
  display: none;
}
.voter_question2 .css-g1d714-ValueContainer {
  height: 40px !important;
  padding: 0px 20px;
}
.voter_question2 .css-b8ldur-Input {
  margin: 0px 2px !important;
}
.voter_question2 .css-tlfecz-indicatorContainer {
  color: rgba(0, 0, 0, 0.6) !important;
}

/******** End of header tabs ************/

.child_btn {
  padding-bottom: 20px;
}
.child_btn button {
  background: #ec7024;
  border: none;
  color: #fff;
  padding: 15px 20px;
  text-transform: uppercase;
  border-radius: 12px;
  display: block;
  width: 100%;
  outline: none;
  font-size: 1rem;
  font-weight: normal;
}

.react-tags__search {
  position: relative;
}
.react-tags__search .react-tags__suggestions {
  margin: 10px 0px;
  background: #f2f2f2;
  display: block;
  position: absolute;
  top: 40px;
  left: 0;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  z-index: 999;
}
.mainchild_div {
  position: relative;
}
.mainchild_div img.plus_circle {
  position: absolute;
  right: 0px;
  top: -1px;
  width: 25px;
  cursor: pointer;
  z-index: 9999;
}
button.minus_btn2 {
  background: none;
  border: none;
  position: absolute;
  top: -5px;
  right: 0px;
  outline: none;
  z-index: 9999;
}
button.minus_btn2 img {
  width: 25px;
}
