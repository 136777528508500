/******** Start of Section of Voter Detail ************/
.voter_detail{
    padding: 20px 0px;
    position: relative;
}
.voter_detail .voterlink_btn{
  text-align: right;  
    
} 
.voter_detail .voterlink_btn a{
    background: #FFE8C8;
    text-decoration: none;
    font-size: 0.8rem;
    border-radius: 15px;
    color: #EC7024;
    border: 1px solid #FFE8C8;
    padding: 6px 15px;
}
.voter_detail .voterlink_btn a img{
    margin-right: 3px;
    position: relative;
    top: 2px;
}
.voter_detail ul{
    list-style-type: none;
    margin: 0px auto;
    display: flex;
    flex-wrap: wrap;
}
.voter_detail ul li{
    margin: 5px auto;
    padding: 0;
}
.voter_detail ul li:nth-child(odd){
    width: 30%;
    
}
.voter_detail ul li:nth-child(even){
    width: 70%;
    color: rgba(0, 0, 0, 0.6);
}
.voter_remark{
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 20px 0px;
    margin: 20px auto;
}
.voter_remark h5{
    font-size: 1rem;
    font-weight: normal;
    margin: 0px auto 20px auto;
}
.voter_remark textarea{
    width: 100%;
    min-height: 80px;
    resize: none;
    border: 1px solid #F8F8F8;
    padding: 10px 10px;
    outline: none;
    background: #F8F8F8;
    border-radius: 10px;
}
.voter_remark button{
    background: #FFE8C8;
    text-decoration: none;
    font-size: 1rem;
    border-radius: 20px;
    color: #EC7024;
    border: 1px solid #EC7024;
    padding: 10px 15px;
    width: 100%;
    margin: 10px auto;
    outline: none;
}
.voter_remark button img{
    margin-right: 5px;
    position: relative;
    top: 1px;
    width: 12px;
}
.voter_remark_border{
    margin: 5px auto;
    border: none;
}
.voter_remark_border h5{
    margin: 0px auto 10px auto;
}
.voter_remark_border h6{
    font-weight: normal;
    margin: 10px auto 5px auto;
    font-size: 0.8rem;
}
.voter_remark_border p{
    color: rgba(0, 0, 0, 0.5);
    margin: 0px auto;
    font-size: 0.9rem;
    line-height: 19px;
}
.voter_remark_border ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}
.voter_remark_border ul li{
    margin: 0;
    padding: 5px;
    
}
.voter_remark_border ul li h6{
    font-size: 0.9rem;
    background: #EEEEEE;
    padding: 5px 10px;
    border-radius: 5px;
    text-align: center;
    margin: 0px auto;
}
.voter_question h5{
    font-weight: normal;
    font-size: 1rem;
    margin: 10px auto;
}
.voter_question h6{
    font-weight: 400;
    font-size: 0.8rem;
    margin: 10px auto 0px auto;
    color: rgba(0, 0, 0, 0.5);
}
.voter_question select{
    width: 100%;
    padding: 12px 10px;
    margin: 10px auto;
    font-size: 1rem;
    background: #F8F8F8;
    border-radius: 12px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.8);
    outline: none;
    border: 1px solid #F8F8F8;
}

.voter_beneficary{
    padding: 20px 0px;
    margin: 10px auto;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.voter_beneficary h5{
    font-weight: normal;
    font-size: 1rem;
    margin: 10px auto;
}
.voter_beneficary .input_loader input{
    width: 100% !important;
    padding: 12px 10px;
    margin: 0px auto 0px auto;
    font-size: 1rem;
    background: #F8F8F8;
    border-radius: 12px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 1);
    outline: none;
    border: 1px solid #F8F8F8;
}
.voter_beneficary .input_loader{
    position: relative;
}
.voter_beneficary .input_loader img{
    
    width: 20px;
    position: absolute;
    top: 20px;
    right: 10px;
    animation: loaderspin 1s linear infinite;
    opacity: 0.5;
}
@keyframes loaderspin{
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
.voter_beneficary ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    
}
.voter_beneficary ul li{
    /* width: 30%; */
    margin-right: 5px;
}
.voter_beneficary ul li p{
    background: rgba(212, 212, 212, 0.33);
    padding: 5px 30px 5px 5px;
    margin: 10px 0px;
    font-size: 0.7rem;
    border-radius: 5px;
    position: relative;
}
.voter_beneficary ul li p span{
    position: absolute;
    right: 7px;
    background: rgba(0, 0, 0, 0.5);
    padding: 0px 4px;
    border-radius: 10px;
    font-size: 0.6rem;
    color: #fff;
}
.voter_beneficary .select_suggestion ul{
    list-style-type: none;
    margin: 0;
    padding: 0px;
    background: #FBFBFB;
    min-height: 0px;
    overflow-y: scroll;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
}
.voter_beneficary .select_suggestion ul li{
    margin: 0;
    padding: 5px 10px;
    display: block;
}
.voter_checkbox ul{
    list-style-type: none;
    margin: 10px auto;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}
.voter_checkbox ul li{
    margin: 10px 0px;
    padding: 0px 10px;
}
.voter_submit button{
    background: #EC7024;
    color: #fff;
    text-transform: uppercase;
    font-size: 1rem;
    border-radius: 12px;
    margin: 20px auto;
    display: block;
    text-align: center;
    outline: none;
    padding: 10px;
    border: none;
    width: 100%;
}
/* The container_check */
.container_check {
    display: block;
    position: relative;
    padding-left: 20px;
    margin-top: 0px;
    cursor: pointer;
    font-size: 1rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .container_check input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: #fff;
    border: 1px solid #EC7024;
    border-radius: 50%;
  }
  
  /* On mouse-over, add a grey background color */
  .container_check:hover input ~ .checkmark {
    background-color: #fff;
  }
  
  /* When the checkbox is checked, add a blue background */
  .container_check input:checked ~ .checkmark {
    background-color: #fff;
    border: 1px solid #EC7024;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .container_check input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .container_check .checkmark:after {
    left: 2px;
    top: 2px;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background: #EC7024;
  }
/******** End of Section of Voter Detail ************/
