ul.boothpre_detail{
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    border-bottom: none;
    padding: 20px 20px;
    background: #F8F8F8;
}
ul.boothpre_detail li{
    margin: 0;
    padding: 7px 0px;
    width: 50%;
}
ul.boothpre_detail li h5{
    font-weight: normal;
    font-size: 1rem;
}
ul.boothpre_detail li h5.boothpre_label{
    color: rgba(0, 0, 0, 0.5);
    text-align: left;
}

/******** Start of Section of Voter List ************/
.voter_container_border{
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.voter_listing{
    margin: 20px 0px;
    display: flex;
    flex-wrap: wrap;
}
.voter_listing a{
    width: 50%;
}
.voter_listing ul{
    list-style-type: none;
    margin: 0px;
    padding: 0px 10px 0px 10px;
    display: flex;
    align-items: center; 
    
}
.voter_listing ul li{
    margin: 10px auto;
    padding: 20px 10px;
    width: 100%;
    background: #fff;
    border-radius: 12px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}
.voter_listing ul li img{
    display: block;
    margin: 0px auto;
    width: 40px;
    height: 40px;
}
/* .voter_listing ul li:nth-child(1){
    padding: 0px 20px 0px 0px;
}
.voter_listing ul li:nth-child(3){
    flex-grow: 1;
    text-align: right;
} */
.voter_listing ul li h5{
    font-size: 1rem;
    color: #000;
    text-align: center;
    font-weight: normal;
    margin: 20px auto 0px auto;
}

/******** End of Section of Voter List ************/