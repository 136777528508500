.personaldetail_list ul{
    list-style-type: none;
    margin: 0;
    padding: 0px 0px;
    display: flex;
    flex-wrap: wrap;
}
.personaldetail_list ul li{
    margin: 0;
    padding: 10px 0px;
    width: 50%;
}
.personaldetail_list ul li h5{
    font-size: 1rem;
    font-weight: normal;
}
.personaldetail_list ul li h5.detail_color{
    color: rgba(0, 0, 0, 0.5);
}
.personaldetail_list ul li button{
    background: #EEEEEE;
    border: 1px solid #EEEEEE;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 0.8rem;
}