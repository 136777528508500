/******** Start of Section of Seva Sangathan ************/

.sangathan_heading{
    padding: 20px 0px;
    
}
.sangathan_heading h4{
    color: #000;
    font-size: 1.2rem;
    font-weight: normal;
    margin: 0px auto;
    padding: 0px 0px;
}
.sangathan_heading h4.search_postion2{
    text-align: left;
}
.sangathan_heading h6{
    font-weight: bold;
    font-size: 1rem;
    margin: 0px auto 0px auto;
    padding: 40px 0px 10px 0px;
}
/* .sangathan_heading select{
    width: 100%;
    padding: 12px 10px;
    margin: 10px auto;
    font-size: 1rem;
    background: #fff;
    border-radius: 5px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.8);
    outline: none;
    border: 1px solid #C4C4C4;
} */
.sangathan_heading .voter_search{
    position: relative;
}
.sangathan_heading .voter_search input{
    width: 100%;
    padding: 12px 10px 12px 35px;
    margin: 10px auto 0px auto;
    font-size: 1rem;
    background: #F8F8F8;
    border-radius: 12px;
    /* box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1); */
    color: rgba(0, 0, 0, 1);
    outline: none;
    border: 1px solid #F8F8F8;
}
.sangathan_heading .voter_search input::placeholder{
    color: rgba(0, 0, 0, 0.4);
}
.search_postion{
    position: relative;
    margin-bottom: 10px;
}
.sangathan_heading .voter_search button.search_postion_btn{
    display: block;
    background: #EC7024;
    text-decoration: none;
    color: #fff;
    text-transform: uppercase;
    padding: 10px 20px;
    width: 100%;
    margin: 0px auto;
    border-radius: 12px;
    font-size: 1rem;
    font-weight: normal;
    border: none;
}
.sangathan_heading .voter_search img{
    position: absolute;
    top: 23px;
    left: 10px;
    width: 15px;
}
.sangathan_heading ul{
    list-style-type: none;
    margin: 20px auto;
    padding: 0;
    display: table;
    width: 100%;
    text-align: center;
}
.sangathan_heading ul li{
    margin: 0;
    padding: 0;
    display: table-cell;
}
.sangathan_heading ul li select{
    background: #F8F8F8;
    border-radius: 10px;
    width: 100%;
    vertical-align: middle;
    border: 1px solid #f8f8f8;
    padding: 10px;
}
/******** End of Section of Seva Sangathan ************/