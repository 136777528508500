.voter_question2 img.user_profile{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: block;
    margin: 10px auto;
}
.voter_question2 h5{
    margin: 0px auto;
    text-align: center;
    font-size: 1.2rem;
    font-weight: normal;
}
.voter_question2 h5 img{
    width: 12px;
    margin-left: 5px;
}
.personaldetail_list ul{
    list-style-type: none;
    margin: 0;
    padding: 20px 0px;
    display: flex;
    flex-wrap: wrap;
}
.personaldetail_list ul li{
    margin: 0;
    padding: 10px 0px;
    width: 50%;
}
.personaldetail_list ul li h5{
    font-size: 1rem;
    font-weight: normal;
}
.personaldetail_list ul li h5.detail_color{
    color: rgba(0, 0, 0, 0.5);
}
.approve_btn{
    position: fixed;
    bottom: 40px;
    
    width: 100%;
    margin: 0px auto;
}
.approve_btn button{
    background: #EC7024;
    border: none;
    color: #fff;
    padding: 12px;
    text-transform: uppercase;
    border-radius: 12px;
    display: block;
    box-shadow: 2px 2px 10px rgba(236, 112, 36, 0.4);
    width: 40%;
    outline: none;
    font-size: 1rem;
    font-weight: normal;
    margin: 0px auto;
}
.updatelist_bg{
    background: #F8F8F8;
}
.personallist_popup{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
}
.compare_data{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
}
.compare_data h4{
    text-align: center;
    position: relative;
    font-weight: normal;
    font-size: 1.2rem;
}
.compare_data h4 span{
    position: absolute;
    right: 0;
    font-size: 1.4rem;
    top: -10px;
    
}
.compare_data ul{
    list-style-type: none;
    display: flex;
    flex-direction: column;
    height: 150px;
    overflow-y: scroll;
}
.compare_data ul li{
    margin: 0;
    padding: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.compare_data ul li h6{
    color: #EC7024;
    font-weight: normal;
    margin: 10px auto 0px auto;
}
.compare_data ul li h5{
    margin: 10px auto;
    font-size: 1rem;
    font-weight: normal;
}
button.ok_btn{
    background: #EC7024;
    color: #fff;
    text-transform: uppercase;
    font-size: 1rem;
    border: none;
    width: 60%;
    margin: 0px auto;
    text-align: center;
    padding: 12px;
    border-radius: 15px;
    display: block;
}