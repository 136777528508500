/******** Start of Section of Booth List Detail ************/
.boothdetail_container{
    padding: 20px 0px;
    margin: 0px auto;
}
.boothdetail_container h4{
    color: #EC7024;
    text-align: center;
    font-weight: bold;
    font-size: 1.2rem;
    margin: 10px auto;
}
.boothdetail_container ul.boothlist_detail{
    list-style-type: none;
    margin: 0;
    padding: 20px 0px;
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.boothdetail_container ul.boothlist_detail li{
    margin: 0;
    padding: 5px 0px;
    width: 50%;
}
.boothdetail_container ul.boothlist_detail li h5{
    font-size: 0.9rem;
    font-weight: normal;
}
.boothdetail_container ul.boothlist_detail li.boothdetail_no h5{
    color: rgba(0, 0, 0, 0.6);
}
.boothdetail_container ul.boothlist_detail li.boothdetail_no h5 a{
    text-decoration: none;
    background: #FFE8C8;
    color: #EC7024;
    padding: 5px 15px;
    border-radius: 20px;
    font-size: 0.8rem;
    margin: 0px 0px 0px 10px;
    float: right;
}
.boothdetail_container ul.boothlist_detail li.boothdetail_no h5 a img{
    margin-left: 5px;
}
.booth_select{
    padding: 20px 0px;
}
.booth_select select{
    padding: 10px 10px;
    width: 100%;
    border: 1px solid #F8F8F8;
    border-radius: 10px;
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.6);
    background: #F8F8F8;
    /* box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1); */
}
.boothdetail_img img.main_img{
    display: block;
    margin: 20px auto;
}
.boothdetail_img ul.bootdetail_img_list{
    list-style-type: none;
    margin: 0;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
}
.boothdetail_img ul.bootdetail_img_list li{
    margin: 0;
    padding: 5px 20px 5px 20px;
}
.boothdetail_img ul.bootdetail_img_list li h6{
    font-size: 0.9em;
    display: inline-flex;
}
.boothdetail_img ul.bootdetail_img_list li h6 img{
    width: 15px;
    margin-right: 10px;
    
}
.boothdetail_img ul.bootdetail_img_list li h6 span{
    color: rgba(0, 0, 0, 0.5);
    position: relative;
    top: -3px;
}
.boothdetail_img a.booth_view{
    background: #EC7024;
    display: block;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    color: #fff;
    text-transform: uppercase;
    border-radius: 12px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    font-size: 1rem;
    font-weight: normal;
}
.chart_section{
    display: flex;
    align-items: center;
    background: #f8f8f8;
    padding: 0px 20px;
    justify-content: center;
    margin: 0px auto 20px auto;
    border-radius: 20px;
}
/******** End of Section of Booth List Detail ************/