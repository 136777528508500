/******** Start of Section of Seva Sangathan Header ************/
.voter_header{
    background: #fff;
    padding: 15px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
}
.voter_header ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.voter_header ul li{
    padding: 0px;
}
.voter_header ul li button{
    background: none;
    border: none;
}
.voter_header ul li.voter_backbtn_padding{
    padding-right: 20px;
    margin-top: 2px;
}
.voter_header ul li.voter_backbtn{
    flex-grow: 1;
}
.voter_header ul li.voter_backbtn h5{
    color: #EC7024;
    font-size: 1rem;
    font-weight: normal;
}
.voter_header ul li.notify_padding{
    padding: 0px 20px 0px 20px;
}
.voter_header ul li button.next_btn{
    color: #EC7024;
    border: 1px solid #FFE8C8;
    padding: 9px 20px;
    background: #FFE8C8;
    font-weight: normal;
    border-radius: 10px;
    outline: none;
    font-size: 1rem;
    
}
.voter_header ul li.dropdown_list ul.user_dropdown{
    list-style-type: none;
    display: none;
    width: 160px;
    background: #fff;
    border-radius: 5px;
    padding: 0px;
    position: absolute;
    z-index: 999;
    right: 10px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}
.voter_header ul li.dropdown_list:hover ul.user_dropdown{
    display: block;
}
.voter_header ul li ul.user_dropdown li{
    margin: 0px;
    padding: 7px 15px;
    
}
.voter_header ul li ul.user_dropdown li:active{
    background: #F3EFEF;
}
.voter_header ul li ul.user_dropdown li a{
    text-decoration: none;
    color: #000;
    font-size: 1rem;
}
.voter_header ul li ul.user_dropdown li a img{
    margin-right: 5px;
}
/******** End of Section of Seva Sangathan Header ************/


/******** Start of Section of Seva Sangathan Header ************/
.voter_header{
    background: #fff;
    padding: 15px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
}
.voter_header ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.voter_header ul li{
    padding: 0px;
}
.voter_header ul li button{
    background: none;
    border: none;
}
.voter_header ul li.voter_backbtn_padding{
    padding-right: 20px;
    margin-top: 2px;
}
.voter_header ul li.voter_backbtn{
    flex-grow: 1;
}
.voter_header ul li.voter_backbtn h5{
    color: #EC7024;
    font-size: 1rem;
    font-weight: normal;
}
.voter_header ul li.notify_padding{
    padding: 0px 20px 0px 20px;
}
.voter_header ul li button.next_btn{
    color: #EC7024;
    border: 1px solid #FFE8C8;
    padding: 9px 20px;
    background: #FFE8C8;
    font-weight: normal;
    border-radius: 10px;
    outline: none;
    font-size: 1rem;
    
}
.voter_header ul li.dropdown_list ul.user_dropdown{
    list-style-type: none;
    display: none;
    width: 160px;
    background: #fff;
    border-radius: 5px;
    padding: 0px;
    position: absolute;
    z-index: 999;
    right: 10px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}
.voter_header ul li.dropdown_list:hover ul.user_dropdown{
    display: block;
}
.voter_header ul li ul.user_dropdown li{
    margin: 0px;
    padding: 7px 15px;
    
}
.voter_header ul li ul.user_dropdown li:active{
    background: #F3EFEF;
}
.voter_header ul li ul.user_dropdown li a{
    text-decoration: none;
    color: #000;
    font-size: 1rem;
}
.voter_header ul li ul.user_dropdown li a img{
    margin-right: 5px;
}
/******** End of Section of Seva Sangathan Header ************/