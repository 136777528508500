.MuiSlider-root{
    color: #ec7024 !important;
    position: absolute !important;
    bottom: 40px;
    right: 10px;
    left: 10px;
    width: 87% !important;
    margin: 0px auto;
}
.MuiSlider-rail{
    background-color: #fff !important;
    opacity: 1 !important;
}

.container-button{
    position: absolute;
    bottom: 10px;
    text-align: center;
    width: 90%;
}
.container-button button{
    margin: 0px 10px 0px 0px;
}
.MuiButton-containedPrimary{
    background-color: #ec7024 !important;
} 