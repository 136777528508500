.voter_search{
  padding: 10px 0px;
  position: relative;
  text-align: right;
}
.voter_search input{
  padding: 15px 10px 15px 35px;
  background: none;
  border: 1px solid #F8F8F8;
  background: #F8F8F8;
  border-radius: 10px;
  width: 100%;
  font-size: 0.8rem;
  outline: none;
}

.voter_search img{
  position: absolute;
  left: 15px;
  top: 25px;
  width: 12px;
}
.input_range_list ul{
  list-style-type: none;
  margin: 20px auto;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.input_range_list ul li{
  margin: 0;
  padding: 0px;
}
.input_range_list ul li p{
  margin: 0px 10px;
}
.input_range_list ul li.range_padding{
  padding: 0px 0px 0px 0px !important;
}
.input_range_list ul li input{
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border-radius: 10px !important;
  border: none;
  background: #fff;
  box-shadow: none !important;
  outline: none;
  border: 1px solid #C5C5C5 !important;
  color: #535353;
}
.input_range_list ul li input::placeholder{
  color: #535353;
  
}
.loksabha_list ul{
  list-style-type: none;
  margin: 0;
  padding: 10px 0px;
  display: flex;
  flex-wrap: wrap;
}
.loksabha_list ul li{
  margin: 0;
  padding: 5px;
  width: 25%;
}
.loksabha_list ul li h5{
  text-align: center;
  margin: 0px auto;
  background: #fff;
  border: 1px solid #DCDCDC;
  font-size: 1.4rem;
  padding: 20px;
  color: #EC7024;
  border-radius: 5px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  font-weight: normal;
}
.loksabha_list ul li h5.active_voter{
  color: #000;
  background: #5AFFB2;
  border: 1px solid #5AFFB2;
}
.assignvoter_btn ul{
  list-style-type: none;
  margin: 20px 0px;
  padding: 0px;
  display: flex;
  justify-content: space-between;
}
.assignvoter_btn ul li{
  margin: 0;
  padding: 0px 0px 0px 0px !important;
  width: 100%;
}
.assignvoter_btn ul li button.assign_btn2{
  background: #EC7024;
  border: 1px solid #EC7024;
  font-size: 1rem;
  text-transform: uppercase;
  outline: none;
  color: #fff;
  width: 100%;
  padding: 12px;
  border-radius: 10px;
  display: block;
  outline: none;
}
.assignvoter_btn ul li button.unassign_btn2{
  background: #FFE8C8;
  border: 1px solid #EC7024;
  font-size: 1rem;
  text-transform: uppercase;
  outline: none;
  color: #EC7024;
  width: 100%;
  padding: 12px;
  border-radius: 10px;
  display: block;
  outline: none;
}
.rc-slider-rail{
  height: 7px !important;
}
.rc-slider-track{
  height: 7px !important;
}
.rc-slider-step{
  height: 7px !important;
}
.rc-slider-handle{
  width: 24px !important;
  height: 24px !important;
  top: 0;
  border: 1px solid #fff !important;
}
.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging{
  box-shadow: none !important;
  border: none !important;
}
.assignvoter_popupbg{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}
.assignvoter_popup{
  position: absolute;
  background: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0px;
  border-radius: 20px;
  width: 90%;
  height: 90%;
  
}
.assignlist_scroll{
  padding:  0px 20px 20px 20px;
  height: 90%;
  overflow-y: auto;
}
.assignvoter_popup h4{
  text-align: center;
  font-size: 1.4rem;
  margin: 20px auto 0px auto;
}
.assignlist_scroll ul{
  list-style-type: none;
  margin: 0px 0px;
  padding: 10px 0px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.assignlist_scroll ul:last-child{
  margin-bottom: 60px;
  border-bottom: none;
}
.assignlist_scroll ul li{
  margin: 0;
  padding: 0;
}
.assignlist_scroll ul li:nth-child(2){
  flex-grow: 1;
}
.assignvoter_popup ul li h6{
  font-size: 1rem;
  font-weight: 400;
}
.assignlist_scroll ul li h5{
  font-size: 1rem;
  margin: 5px auto;
  font-weight: 400;
  display: flex;
  align-items: flex-start;
}
.assignlist_scroll ul li h5 img{
  width: 12px;
  margin: 0px 5px 0px 5px;
}
.otp_container2{
  padding: 0px;
  position: fixed;
  bottom: 30px;
  left: 0px;
  width: 100%;
}
.otp_container2 ul{
  list-style-type: none;
  margin: 0px auto 0px auto;
  padding: 0;
  display: flex;
  justify-content: center;
}
.otp_container2 ul li{
  padding: 0px 10px;
}
.otp_container2 ul li button{
  border: 1px solid rgba(236, 112, 36, 1);
  text-transform: uppercase;
  font-size: 1rem;
  padding: 12px 40px;
  border-radius: 10px;
  font-weight: normal;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
}
.otp_container2 ul li button.verify_btn2{
  background: #EC7024;
  color: #fff;
}
.otp_container2 ul li button.cancel_btn2{
  background: #FFE8C8;
  color: #EC7024;
}