.voter_search{
    padding: 10px 0px;
    position: relative;
    text-align: right;
}
.voter_search input{
    padding: 15px 10px 15px 35px;
    background: none;
    border: 1px solid #F8F8F8;
    background: #F8F8F8;
    border-radius: 10px;
    width: 100%;
    font-size: 0.8rem;
    outline: none;
}

.voter_search img{
    position: absolute;
    left: 15px;
    top: 25px;
    width: 12px;
}
.input_range_list ul{
    list-style-type: none;
    margin: 20px auto;
    padding: 0;
    display: flex;
    justify-content: space-between;
}
.input_range_list ul li{
    margin: 0;
    padding: 0px;
}
.input_range_list ul li.range_padding{
    padding: 0px 20px 0px 0px;
}
.input_range_list ul li input{
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border-radius: 20px;
    border: none;
    background: #fff;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    outline: none;
}
.input_range_list ul li input::placeholder{
    color: rgba(0, 0, 0, 0.4);
}
.loksabha_list ul{
    list-style-type: none;
    margin: 0;
    padding: 10px 0px;
    display: flex;
    flex-wrap: wrap;
}
.loksabha_list ul li{
    margin: 0;
    padding: 5px;
    width: 25%;
}
.loksabha_list ul li h5{
    text-align: center;
    margin: 0px auto;
    background: #fff;
    border: 1px solid #DCDCDC;
    font-size: 1.4rem;
    padding: 20px;
    color: #EC7024;
    border-radius: 5px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    font-weight: normal;
}
.loksabha_list ul li h5.active_voter{
    color: #000;
    background: #5AFFB2;
    border: 1px solid #5AFFB2;
}
.loksabha_list ul li h5.un-active_voter{
    color: #fff;
    background: red;
    border: 1px solid red;
}
.assignvoter_btn ul{
    list-style-type: none;
    margin: 20px 0px;
    padding: 0px;
    display: flex;
    justify-content: space-between;
}
.assignvoter_btn ul li{
    margin: 0;
    padding: 0px 20px 0px 0px;
    width: 100%;
}
.assignvoter_btn ul li button.assign_btn2{
    background: #EC7024;
    border: 1px solid #EC7024;
    font-size: 1rem;
    text-transform: uppercase;
    outline: none;
    color: #fff;
    width: 100%;
    padding: 12px;
    border-radius: 10px;
    display: block;
    outline: none;
}
.assignvoter_btn ul li button.unassign_btn2{
    background: #FFE8C8;
    border: 1px solid #EC7024;
    font-size: 1rem;
    text-transform: uppercase;
    outline: none;
    color: #EC7024;
    width: 100%;
    padding: 12px;
    border-radius: 10px;
    display: block;
    outline: none;
}
.rc-slider-rail{
    height: 7px !important;
}
.rc-slider-track{
    height: 7px !important;
}
.rc-slider-step{
    height: 7px !important;
}
.rc-slider-handle{
    width: 24px !important;
    height: 24px !important;
    top: 0;
    border: 1px solid #fff !important;
}
.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging{
    box-shadow: none !important;
    border: none !important;
}

.total-count h5 {
    font-size: 1.4rem;
    text-align: center;
    margin: 20px auto 0px auto;
    font-weight: 500;
}