
/******** Start Section of Login Screen ************/
.bjp_headsection{
    margin: 0px auto;
    text-align: center;
    padding: 0px 0px 20px 0px;
}
.bjp_headsection img{
    width: 100%;
}
.bjp_headsection h4{
    color: #EC7024;
    text-transform: uppercase;
    text-align: center;
    font-size: 1.1rem;
	font-weight: bold;
    margin: 0px auto;
    
}

.bjplogin_page{
    text-align: center;
    margin: 0px auto;
}
.bjplogin_page h5{
    color: #EC7024;
    font-size: 1.3rem;
    font-weight: normal;
    margin: 0px auto;
    padding-bottom: 10px;
}
.bjplogin_page .member_select{
    position: relative;
}
.bjplogin_page .member_select select{
    width: 90%;
    padding: 10px 20px;
    border-radius: 20px;
    margin: 10px auto;
    font-size: 1rem;
    border: 1px solid #C4C4C4;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    background: none;
}
.bjplogin_page .member_select img{
    position: absolute;
    top: 25px;
    right: 30px;
    z-index: -1;
}
.bjplogin_page input{
    width: 90%;
    margin: 0px auto;
    padding: 10px 20px 10px 30px;
    border-radius: 12px;
    font-size: 1rem;
    border: 1px solid #f1f1f1;
    outline: none;
    background: #f1f1f1;
}
.phone_postion{
    position: relative;
    padding: 10px 0px;
}
.phone_postion img{
    position: absolute;
    top: 20px;
    left: 28px;
}
.bjplogin_page a{
    display: block;
    background: #EC7024;
    text-decoration: none;
    color: #fff;
    text-transform: uppercase;
    padding: 10px 20px;
    width: 90%;
    margin: 0px auto;
    border-radius: 12px;
    font-size: 1rem;
    font-weight: normal;
}
/******** End Section of Login Screen ************/



/******** Start of Section of OTP Screen ************/
.otp_container{
    padding: 40px 0px;
    text-align: center;
    margin: 0px auto;
}
.otp_container h4{
    color: #000;
    font-weight: normal;
    font-size: 1.2rem;
    margin: 10px auto;
}
.otp_container p{
    color: rgba(0, 0, 0, 0.5);
    font-size: 1rem;
    margin: 0px auto 30px auto;
    max-width: 250px;
}
.otp_container form.otp fieldset{
    border: none;
    text-align: center;
    margin: 0px auto;
}
.otp_container form.otp fieldset input{
    width: 40px;
    height: 45px;
    padding: 5px;
    margin-right: 0px;
    border: 1.5px solid #f1f1f1;
    border-radius: 5px;
    text-align: center;
    font-size: 1rem;
    margin: 0px 5px;
    outline: none;
    background: #f1f1f1;
} 
/* .otp_container form.otp fieldset input.active_input{
    border: 1.5px solid rgba(236, 112, 36, 1);
} */
.otp_container h5{
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.4);
    margin: 10px auto;
    font-weight: normal;
}
.otp_container h6{
    margin: 40px auto 0px auto;
    color: #949494;
    font-size: 1rem;
    font-weight: normal;
}
.otp_container h6 a{
    text-decoration: none;
    color: #000;
}
.otp_container h6 a img{
    margin-top: 2px;
    margin-right: 10px;
    position: relative;
    top: 3px;
}
.otp_container ul{
    list-style-type: none;
    margin: 40px auto 0px auto;
    padding: 0;
    display: flex;
    justify-content: center;
}
.otp_container ul li{
    padding: 0px 10px;
}
.otp_container ul li a{
    text-decoration: none;
    border: 1px solid rgba(236, 112, 36, 1);
    text-transform: uppercase;
    font-size: 1rem;
    padding: 12px 40px;
    border-radius: 10px;
    font-weight: normal;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
}
.otp_container ul li a.verify_btn{
    background: #EC7024;
    color: #fff;
}
.otp_container ul li a.cancel_btn{
    background: #FFE8C8;
    color: #EC7024;
}
/******** End of Section of OTP Screen ************/