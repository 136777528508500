.assignvoter_popup2{
    position: absolute;
    background: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    border-radius: 20px;
    width: 90%;
    min-height: 35%;
}
.assignvoter_popup2 h4{
    font-size: 1.3rem;
    font-weight: 590;
    text-align: center;
    margin: 0px auto 10px auto;
}
.karkartapopup2 ul{
    list-style-type: none;
    margin: 0;
    padding: 5px 0px;
    display: flex;
    align-items: flex-start;
}
.karkartapopup2 ul li{
    margin: 0;
    padding: 0px 10px 0px 0px;
}
.karkartapopup2 ul li:nth-child(1){
    width: 25%;
}
.karkartapopup2 ul li h5{
    font-size: 1rem;
    font-weight: 500;
}