/******** Start of Section of Seva Booth List ************/
.sevabooth_container{
    padding: 0px 0px;
    margin: 0px auto;
}
.sevabooth_container h4{
    color: #EC7024;
    text-align: center;
    font-weight: bold;
    font-size: 1.2rem;
    margin: 10px auto;
}
.sevabooth_container ul.sevabooth_list{
    list-style-type: none;
    margin: 0;
    padding: 5px 0px;
    display: flex;
    justify-content: space-between;
    
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.sevabooth_container ul.sevabooth_list li{
    margin: 0;
    padding: 0;
}
.sevabooth_container ul.sevabooth_list li:nth-child(1){
    width: 50%;
    align-items: flex-start;
}
.sevabooth_container ul.sevabooth_list li:nth-child(2){
    width: 50%;
    align-items: flex-start;
}
.sevabooth_container ul.sevabooth_list li:nth-child(3){
    align-items: center;
    display: flex;
}
.sevabooth_container ul.sevabooth_list li h5{
    font-size: 0.9rem;
    margin: 15px auto;
    font-weight: 590; 
    color: #000;
    font-weight: 500;
}
.sevabooth_container ul.sevabooth_list li.booth_number h5{
    color: rgba(0, 0, 0, 0.6);
}
.sevabooth_container ul.sevabooth_list li.booth_number h5 a{
    text-decoration: none;
    background: #FFE8C8;
    color: #EC7024;
    padding: 5px 15px;
    border-radius: 20px;
}
.sevabooth_container ul.sevabooth_list li.booth_number h5 a img{
    margin-left: 5px;
}
/******** End of Section of Seva Booth List ************/