.react-confirm-alert-overlay{
  z-index: 999;
}
.react-confirm-alert {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    margin: 0px auto;
  }
  .react-confirm-alert-body{
    width: 100%;
    box-shadow: 0 20px 75px rgba(0, 0, 0, 0.35);
  }
  .react-confirm-alert-button-group button{
    border: 1px solid rgba(236, 112, 36, 1);
    text-transform: uppercase;
    font-size: 1rem;
    padding: 6px 20px;
    border-radius: 5px;
    font-weight: normal;
    box-shadow: 2px 2px 10px rgb(0 0 0 / 20%);
    background: #FFE8C8;
    color: #EC7024;
  }
  .react-confirm-alert-button-group button:nth-child(1){
    background: #EC7024;
    color: #fff;
  }